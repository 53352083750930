import React from "react"
import tw from "twin.macro"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Button from "../components/button"
import Container from "../components/container"
import Hero from "../components/hero"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Cta from "../components/cta"

// Assets
import caution from "../images/icons/caution.svg"
import tablets from "../images/icons/tablets.svg"
import taste from "../images/icons/taste.svg"
import Callout from "../components/callout"

const Dosing = () => {
  const data = useStaticQuery(graphql`
    query {
      ctaImage: file(relativePath: { eq: "dosing/cta.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 720
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
      heroImage: file(relativePath: { eq: "dosing/hero.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 720
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
      twoTimesDaily: file(relativePath: { eq: "icons/two-times-daily.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 162
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
      withOrWithoutFood: file(
        relativePath: { eq: "icons/with-or-without-food.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 400
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
      smallImage: file(relativePath: { eq: "backgrounds/cta-small-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1440
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
      largeImage: file(relativePath: { eq: "backgrounds/cta-large-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
    }
  `)

  return (
    <Layout activeLinkIndex={2}>
      <Hero styles={tw`mb-20 pb-[43px] lg:(mb-24)`}>
        <Container>
          <div tw="grid grid-cols-6 gap-x-7 lg:(grid-cols-12)">
            <h1 tw="col-span-4 font-light uppercase text-32 mt-1 pb-24 lg:(col-span-6 text-43 mt-5 pb-6) xl:(text-6xl mb-6 [line-height: 1.4])">
              How should I take <span tw="font-black">Livtencity</span>?
            </h1>
            <div tw="col-span-full lg:(relative col-span-6)">
              <GatsbyImage
                image={getImage(data.heroImage)}
                css={[
                  tw`absolute -bottom-[58px] -right-[34px] w-[250px] lg:(w-[67%] right-[45px] bottom-[unset] top-[-50px]) xl:(top-[-70px])`,
                ]}
                alt=""
              />
            </div>
          </div>
        </Container>
      </Hero>
      <section>
        <Container>
          <h2 tw="uppercase font-light text-32 [-webkit-text-fill-color: transparent] [-webkit-background-clip: text] bg-ctaText lg:(text-34 pb-4) xl:(text-5xl pb-8)">
            Livtencity <span tw="font-black">should be taken</span>
          </h2>
          <div>
            <div tw="uppercase font-bold text-2xl pb-10 lg:(flex justify-between text-xl leading-[1.17] ml-11 pb-[28px]) xl:(text-27 leading-[1.17] ml-14 pb-9)">
              <div tw="flex pt-10 gap-[30px] lg:(flex-col items-center justify-between text-center gap-[35px]) xl:(gap-[50px])">
                <GatsbyImage
                  image={getImage(data.twoTimesDaily)}
                  css={[tw`w-20 lg:(w-[87px]) xl:(w-[122px])`]}
                  objectFit="cover"
                  objectPosition="top center"
                  alt=""
                />
                <p tw="self-center mt-1.5 lg:(mt-0 mb-3)">2 times a day</p>
              </div>
              <div tw="pt-10 lg:(flex flex-col items-center justify-between text-center)">
                <GatsbyImage
                  image={getImage(data.withOrWithoutFood)}
                  css={[tw`w-[215px] lg:(w-[164px]) xl:(w-[231px])`]}
                  objectFit="cover"
                  objectPosition="top center"
                  alt=""
                />
                <p tw="mt-6 lg:(mt-0 mb-3)">with or without food</p>
              </div>
              <div tw="flex pt-10 gap-[30px] lg:(flex-col items-center justify-between text-center mr-4) xl:(mr-10)">
                <div tw="w-[80px] shrink-0 lg:(w-full)">
                  <img
                    alt=""
                    tw="mx-auto w-16 lg:(w-[55px] relative -top-2.5) xl:(w-[77px])"
                    src={tablets}
                  />
                </div>
                <p tw="self-center mt-0.5 lg:(mt-0)">
                  swallow <br tw="hidden lg:(block)" />
                  tablets whole
                </p>
              </div>
            </div>
            <p tw="relative font-light pt-8 before:(absolute left-0 top-0 bg-coral h-0.5 w-20) lg:(before:(h-[2.5px] w-14)) xl:(text-21 pt-11 before:(h-[3px] w-[83px]))">
              <span tw="font-medium">
                If you can&apos;t swallow tablets whole,
              </span>{" "}
              you can break apart or crush tablets, mix with drinking water, and
              drink.
              <br />
              You can also break apart tablets and take through a nasogastric
              (NG) or orogastric (OG) tube.
            </p>
            <p tw="font-medium pt-3 mb-9 xl:(text-21 mb-11)">
              See the “Instructions for Use” in the{" "}
              <a
                href="https://content.takeda.com/?contenttype=pi&product=liv&language=eng&country=usa&documentnumber=1"
                target="_blank"
                rel="noreferrer"
                className="gtm-download"
                data-documentname="PrescribingInformation"
                data-click="CTAClick"
              >
                <span tw="underline">Prescribing Information</span>
              </a>{" "}
              for detailed instructions.
            </p>
          </div>
          <Callout backgroundAlt>
            <p>
              <span tw="font-black">
                Take <span tw="uppercase">Livtencity</span> exactly as your
                healthcare provider tells you to take it.
              </span>{" "}
              <br />
              If&nbsp;you take too much <span tw="uppercase">Livtencity</span>,
              call your healthcare provider or go to the nearest hospital
              emergency room right away.
            </p>
          </Callout>
        </Container>
      </section>
      <section>
        <Container tw="pb-16 lg:(pb-11) xl:(pb-16)">
          <h2 tw="uppercase font-light text-32 [-webkit-text-fill-color: transparent] [-webkit-background-clip: text] bg-ctaText lg:(text-34) xl:(text-5xl)">
            What can I expect while{" "}
            <span tw="font-black">taking Livtencity?</span>
          </h2>
          <div tw="lg:(flex justify-between pb-7) xl:(text-21 pb-10 gap-[200px])">
            <div tw="pt-10 lg:(w-[40%] pt-7) xl:(pt-12)">
              <div tw="w-[92px] float-left lg:(float-none w-auto)">
                <img
                  alt=""
                  tw="ml-1.5 w-[70px] lg:(w-[56px] mb-4) xl:(w-[79px] mb-[21px])"
                  src={caution}
                />
              </div>
              <p tw="font-light">
                <span tw="font-semibold">
                  Some people may experience&nbsp;side effects{" "}
                  <br tw="lg:(hidden)" />
                  while taking LIVTENCITY.{" "}
                </span>
                <span tw="block mt-2.5 lg:(hidden)" />
                The most common side effects while taking LIVTENCITY include
              </p>
              <ul tw="font-semibold list-disc ml-6 mt-1 lg:(mt-3 columns-2) xl:(mt-5)">
                <li tw="mb-1.5">
                  <span tw="relative left-3 lg:(left-0.5) xl:(left-2)">
                    changes in taste
                  </span>
                </li>
                <li tw="mb-1.5">
                  <span tw="relative left-3 lg:(left-0.5) xl:(left-2)">
                    nausea
                  </span>
                </li>
                <li tw="mb-1.5">
                  <span tw="relative left-3 lg:(left-0.5) xl:(left-2)">
                    diarrhea
                  </span>
                </li>
                <li tw="mb-1.5">
                  <span tw="relative left-3 lg:(left-0.5) xl:(left-2)">
                    vomiting
                  </span>
                </li>
                <li tw="mb-1.5">
                  <span tw="relative left-3 lg:(left-0.5) xl:(left-2)">
                    tiredness
                  </span>
                </li>
              </ul>
            </div>
            <div tw="pt-10 pb-12 lg:(w-1/2 pb-0 pt-7) xl:(pt-12)">
              <div tw="w-[92px] float-left lg:(float-none w-auto)">
                <img
                  alt=""
                  tw="-ml-0.5 w-[86px] lg:(w-[66px] mb-4) xl:(w-[93px])"
                  src={taste}
                />
              </div>
              <p tw="font-light">
                <span tw="font-semibold lg:(block)">
                  Changes in taste were the most&nbsp;common side effect,
                </span>{" "}
                and happened in less than half of&nbsp;patients in the study.
                Changes in taste most often lasted 43 days while taking
                LIVTENCITY, and usually went away within 6 days of stopping
                LIVTENCITY.
              </p>
            </div>
          </div>
          <p tw="relative font-light pt-8 before:(absolute left-0 top-0 bg-coral h-0.5 w-20) lg:(pt-6 before:(h-[2.5px] w-14)) xl:(pt-9 text-21 before:(h-[3px] w-[83px]))">
            These are not all the possible side effects of LIVTENCITY; you
            should always{" "}
            <span tw="font-semibold">
              call your doctor and transplant care team for medical advice about
              side effects.
            </span>
          </p>
        </Container>
      </section>
      <Cta data={data}>
        <section>
          <Container tw="pt-1.5 pb-4 lg:(flex gap-20 pt-11 pb-6) xl:(pt-16 pb-12 gap-36)">
            <GatsbyImage
              image={getImage(data.ctaImage)}
              css={[
                tw`transform scale-x-[-1] max-w-[230px] block mx-auto mb-8 lg:(transform-none max-w-[293px] w-full) xl:(max-w-[420px])`,
              ]}
              objectFit="cover"
              objectPosition="top center"
              alt=""
            />
            <div>
              <h2 tw="font-black text-[28px] leading-[33px] mb-5 lg:(font-semibold text-xl mb-3) xl:(text-27 mb-5 mt-4)">
                Have more questions about <span tw="uppercase">Livtencity</span>
                ?
              </h2>
              <p tw="font-light mb-6 lg:(text-lg mb-4) xl:(text-2xl leading-[1.5] mb-7)">
                Your transplant care team should always be your go-to for
                questions about your treatment plan. If you need help discussing
                these topics with them, download the{" "}
                <a
                  href="/pdf/tips-for-talking-with-your-transplant-team.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="gtm-download"
                  data-documentname="DoctorDiscussionGuide"
                >
                  <span tw="underline font-bold lg:(font-semibold)">
                    Tips for Talking with your Transplant Team
                  </span>
                </a>
                .
              </p>
              <div tw="text-center lg:(text-left ml-2) xl:(ml-3)">
                <Button
                  link="/pdf/tips-for-talking-with-your-transplant-team.pdf"
                  external
                  buttonClass="gtm-download"
                  documentName="DoctorDiscussionGuide"
                >
                  Download Now
                </Button>
              </div>
              <h3 tw="font-black text-2xl leading-7 mt-8 mb-6 lg:(font-semibold text-xl mt-12 mb-4 pb-0.5) xl:(text-27 mt-16 mb-6)">
                Looking for more <br tw="lg:(hidden)" />
                resources?
              </h3>
              <div tw="text-center lg:(text-left ml-2) xl:(ml-3)">
                <Button link="/resources/" buttonClass="gtm-cta-nav">
                  <span tw="lg:(px-5)">Find support and more</span>
                </Button>
              </div>
            </div>
          </Container>
        </section>
      </Cta>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Dosing and Possible Side Effects | LIVTENCITY® (maribavir)"
    description="View information on how LIVTENCITY should be taken, including possible side effects of treatment. See full Safety and Patient Information."
  />
)

export default Dosing
